+function ($) {
    'use strict';


    var controller = new ScrollMagic.Controller,
        navbar = $(".navbar");

    var sc = new ScrollMagic.Scene({
        offset: 80,
        // triggerElement: ".about-section",
        // duration: "100000%"
    })
        .setClassToggle(navbar, "opaque")

        .addTo(controller);

}(jQuery);
